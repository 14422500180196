@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Orbitron&family=Space+Grotesk&display=swap");
* {
  box-sizing: border-box;
  font-family: Orbitron;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* To hide horizontal scrollbar */
}
.root {
  max-width: 100vw; /* This ensures the width does not exceed the viewport width */
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
